import React from 'react';
import Layout from '../../../components/Layout';
import NavOne from '../../../components/NavOne';
import PageHeader from '../../../components/PageHeader';
import Footer from '../../../components/Footer';

import g1 from '../../../assets/images/2019/us-ambassador-visit/1.jpg';
import g2 from '../../../assets/images/2019/us-ambassador-visit/2.jpg';
import g3 from '../../../assets/images/2019/us-ambassador-visit/3.jpg';
import g4 from '../../../assets/images/2019/us-ambassador-visit/4.jpg';
import g5 from '../../../assets/images/2019/us-ambassador-visit/5.jpg';

const BlogLayout = () => {
  return (
    <section className='gallery-one'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-12'>
            <div className='course-details__top-left'>
              <h2 className='course-details__title'>
              US AmbassadorVisit to LUTH
              </h2>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-4 col-md-6'>
              <div className='gallery-one__single'>
                <img src={g1} alt='' />
                <a href={g1} className='gallery-one__popup img-popup'>
                  <i className='kipso-icon-magnifying-glass'></i>
                </a>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='gallery-one__single'>
                <img src={g2} alt='' />
                <a href={g2} className='gallery-one__popup img-popup'>
                  <i className='kipso-icon-magnifying-glass'></i>
                </a>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='gallery-one__single'>
                <img src={g3} alt='' />
                <a href={g3} className='gallery-one__popup img-popup'>
                  <i className='kipso-icon-magnifying-glass'></i>
                </a>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='gallery-one__single'>
                <img src={g4} alt='' />
                <a href={g4} className='gallery-one__popup img-popup'>
                  <i className='kipso-icon-magnifying-glass'></i>
                </a>
              </div>
            </div>
            <div className='col-lg-4 col-md-6'>
              <div className='gallery-one__single'>
                <img src={g5} alt='' />
                <a href={g5} className='gallery-one__popup img-popup'>
                  <i className='kipso-icon-magnifying-glass'></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const EventPage = () => {
  return (
    <Layout pageTitle='Event Gallery | LUTH'>
      <NavOne />
      <PageHeader title='' />
      <BlogLayout />
      <Footer />
    </Layout>
  );
};

export default EventPage;
